import React from 'react';
import classNames from 'classnames';
import { SecondaryButton } from 'jl-design-system/elements/button/Button';
import styles from './FeedbackMenu.scss';

const FeedbackMenu = ({ menu }) => (
  <div
    className={classNames(styles.feedbackMenu)}
    data-testid="feedback-menu-item"
  >
    <h3>{menu.name}</h3>
    <p>{menu.text}</p>
    <SecondaryButton
      tag="a"
      href={menu.button.url}
      {...{ target: '_blank', rel: 'noopener' }}
    >
      {menu.button.label}
    </SecondaryButton>
  </div>
);

export default FeedbackMenu;
